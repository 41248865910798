import { Button, ButtonClassKey, ButtonProps } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { createElement, forwardRef, useImperativeHandle, useRef } from "react";
import { OPACITY_PULSE } from "../AppTheme";
import { UIAction, UIActionObject } from "../types/uiActions";
import {
  renderUIAction,
  UIActionElementOnClickHandler,
  UIActionElementPostActionHandler,
  useUIAction,
} from "../utils/uiActions";
import { Link } from "./Link";
import { QuestStepWrapper } from "./quests/QuestStepWrapper";

const useStyles = makeStyles(
  (theme) => ({
    ...OPACITY_PULSE.keyframes,
    root: {},
    rootLink: {
      "&:hover": {
        textDecoration: "none",
      },
    },
    thinking: {
      ...OPACITY_PULSE.elementStyles,
    },
    destructive: {
      color: theme.palette.error.main,
    },
  }),
  {
    classNamePrefix: "UIActionButton",
  }
);

export type UIActionButtonJSSClassKey = ButtonClassKey & keyof ReturnType<typeof useStyles>;

export type UIActionButtonProps = Omit<ButtonProps, "onClick" | "children" | "classes"> & {
  classes?: Partial<ClassNameMap<UIActionButtonJSSClassKey>>;
  uiAction: UIAction;
  defaults?: Partial<UIActionObject>;
  onClick?: UIActionElementOnClickHandler;
  postAction?: UIActionElementPostActionHandler;
};

export const UIActionButton = forwardRef<HTMLButtonElement, UIActionButtonProps>(
  ({ className, uiAction, classes: extClasses, defaults, onClick, postAction, ...rest }, ref) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const innerRef = useRef<HTMLButtonElement | null>(null);
    useImperativeHandle<HTMLButtonElement | null, HTMLButtonElement | null>(ref, () => innerRef.current);

    const { handleClick, thinking } = useUIAction(uiAction, innerRef, onClick, postAction);

    return renderUIAction(
      "button",
      uiAction,
      ({ actionObject, isLink }) => {
        const muiColor = actionObject.color === "destructive" ? undefined : actionObject.color;

        const button = createElement(
          Button,
          {
            ref: (el) => {
              innerRef.current = el;
              if (actionObject.ref) actionObject.ref.current = el as HTMLElement;
            },
            startIcon: actionObject.icon,
            className: clsx(className, classes.root, {
              [classes.destructive]: actionObject.color === "destructive",
              [classes.thinking]: thinking,
              [classes.rootLink]: isLink,
            }),
            disabled: actionObject.disabled,
            ...rest,
            ...(muiColor && {
              color: muiColor,
            }),
            ...(isLink
              ? ({
                  component: Link,
                  href: actionObject.action,
                  target: actionObject.target,
                } as Partial<ButtonProps>)
              : {
                  onClick: handleClick,
                }),
          },
          actionObject.label
        );

        return !!actionObject.QuestStepWrapperProps ? (
          <QuestStepWrapper {...actionObject.QuestStepWrapperProps}>{button}</QuestStepWrapper>
        ) : (
          button
        );
      },
      { defaults }
    );
  }
);
